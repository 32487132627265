import React, { Component } from 'react';

class Header extends Component {
  
  
  constructor(props) {
    super(props);
    this.headerImageUrl = "http://beerlogger.co/wp-content/uploads/2017/08/bigflatlogo-e1504072948408.png";
    this.headerType = "image";
    let fullUrl = window.location.href;
    if (fullUrl.indexOf('we-love-craft.beer') != -1)
    {
      this.headerType  = "text";
      this.headerText = "We Love Craft Beer"; 
      this.headerImageUrl = "https://we-love-craft-beer-aus.azurewebsites.net";
    }
    else if (fullUrl.indexOf('winelogger.info') != -1) {
      this.headerType  = "image";
      this.headerText = "My Wine Log"; 
      this.headerImageUrl = "http://winelogger.info/wp-content/uploads/2017/09/WI-logger-Logo-with-text-e1504518685857.png";
    }
    else {
      this.headerType  = "image";
      this.headerText = "Beer Logger"; 
      this.headerImageUrl = "http://beerlogger.co/wp-content/uploads/2017/08/bigflatlogo-e1504072948408.png";
    }
  }

  render() {
    let button;
    let logo;
    if (this.headerType == "image") {
      logo =   <img src={ this.headerImageUrl } alt={this.headerText}/>;
    } else {
      logo = <h1>{this.headerText}</h1>;
    }

    return (
        <div className="row">
          <div className="sm-col-12">
          <div className="header-inner">
           {logo}
            </div>
          </div>
        </div>
    );
}}

export default Header