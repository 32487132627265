import React, { Component } from 'react';
import axios from 'axios';
import StarRatingComponent from 'react-star-rating-component';
import MetaTags from 'react-meta-tags';

class LoggedItemView extends Component {


   

    constructor(props) {
        
        var baseApiDomain = "https://beerappdata.azurewebsites.net";
        let fullUrl = window.location.href;
        let guidPos = fullUrl.lastIndexOf("review")+7;
        let gui = fullUrl.substring(guidPos);
        gui = gui.split('?')[0];
        gui = gui.split('&')[0];

        if (fullUrl.indexOf('we-love-craft.beer') != -1)
        {
            baseApiDomain = "https://we-love-craft-beer-aus.azurewebsites.net";
        }
        else if (fullUrl.indexOf('winelogger.info') != -1) {
            baseApiDomain = "https://wineloggerdata.azurewebsites.net";
        }
        else {
            baseApiDomain = "https://beerappdata.azurewebsites.net";
        }
        
        super(props);
        this.state = { date: new Date() };
        this.setState((state, props) => ({
            note: 'loading...'
        }));

        let me = this;

        axios({
            method: 'get',
            url: baseApiDomain+'/api/review/' + gui,
            responseType: 'application/json'
        })
            .then(function (response) {

                me.setState((state, props) => ({
                    note: response.data.note,
                    itemPicUrl: response.data.profilePic,
                    title: response.data.name,
                    providedBy: response.data.about,
                    rating: response.data.stars,
                    reviewerPicUrl: response.data.reviewerProfilePic,
                    reviewer: response.data.reviewerName
                }));
            });

    }

    render() {
        return (
            <div>
            <MetaTags>
                <title>{this.state.title} - {this.state.providedBy}</title>
                <meta name="description" content={this.state.note} />
                <meta property="og:title" content={this.state.title + ' ' + this.state.providedBy} />
                <meta property="og:image" content={this.state.itemPicUrl} />
            </MetaTags>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                    <img className={'img-fluid hero'}  src={this.state.itemPicUrl} alt="Photo of Item" />
                </div>
      

                <div className="col-12 col-sm-12  col-md-6 beer-info">
                    <h5>{this.state.title}</h5>
                    <h6>By {this.state.providedBy}</h6>
                    <div className="row">
                <div className={'col-3 col-sm-3'}>
                    <img className={'img-thumbnail profile-pic'} src={this.state.reviewerPicUrl} alt="Reviewer picture" />
                    <div className="username">
                        {this.state.reviewer}
                    </div>
                </div>
                <div className={'col-9 col-sm-9'}>
                    <div className="note-text">
                        {this.state.note}
                    </div>
                </div>
                <div className={'col-12 col-sm-12 stars-area'}>
                    <StarRatingComponent name="rate1"  starCount={5} value={this.state.rating}/>
                </div>
            </div>         
                </div>
                </div>
                       </div>
            
        );
    }
}

export default LoggedItemView