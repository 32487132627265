import React, { Component } from 'react';

class Marketing extends Component {
    render() {
        let content;
        let fullUrl = window.location.href;
        if (fullUrl.indexOf('we-love-craft.beer') != -1)
        {
            content = <div className={'row marketing'}>
          <div className="col-sm-12 text-center">
              <h3>Download the We Love Craft Beer app today to see more reviews and discover craft beers with the rest of us</h3>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 text-center">
              <a href="https://play.google.com/store/apps/details?id=beer.welovecraftbeer.app" className="hasimg" title=""><img className="alignnone wp-image-118" src="http://beerlogger.co/wp-content/uploads/2017/08/google-play-badge-300x89.png" alt="" width="202" height="60" scale="0"/></a>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 text-center">
              <a href="https://itunes.apple.com/au/app/we-love-craft-beer/id1406268308?mt=8" className="hasimg" title=""><img className="alignnone wp-image-119" src="http://beerlogger.co/wp-content/uploads/2017/08/apple-store-badge.png" alt="" width="207" height="61" scale="0" /></a>
          </div>
            </div>;
        }
        else if (fullUrl.indexOf('winelogger.info') != -1) {
            content = <div className={'row marketing'}>
            <div className="col-sm-12 text-center">
                <h3>Download the My Wine Log app today to see more reviews and discover amazing wines</h3>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                <a href="https://play.google.com/store/apps/details?id=info.wineloggers.app" className="hasimg" title=""><img className="alignnone wp-image-118" src="http://beerlogger.co/wp-content/uploads/2017/08/google-play-badge-300x89.png" alt="" width="202" height="60" scale="0"/></a>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                <a href="https://itunes.apple.com/us/app/wine-logger-rate-and-discover-wines/id1273541667?ls=1&mt=8" className="hasimg" title=""><img className="alignnone wp-image-119" src="http://beerlogger.co/wp-content/uploads/2017/08/apple-store-badge.png" alt="" width="207" height="61" scale="0" /></a>
            </div>
              </div>;
        }
        else {
            content = <div className={'row marketing'}>
            <div className="col-sm-12 text-center">
                <h3>Download the Beer Logger app today to see more reviews and discover craft beers with the rest of us</h3>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                <a href="https://play.google.com/store/apps/details?id=info.beerlogger.app" className="hasimg" title=""><img className="alignnone wp-image-118" src="http://beerlogger.co/wp-content/uploads/2017/08/google-play-badge-300x89.png" alt="" width="202" height="60" scale="0"/></a>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                <a href="https://itunes.apple.com/us/app/beer-logger/id1223273174" className="hasimg" title=""><img className="alignnone wp-image-119" src="http://beerlogger.co/wp-content/uploads/2017/08/apple-store-badge.png" alt="" width="207" height="61" scale="0" /></a>
            </div>
              </div>;
        }

        return (
            <div>            {content}</div>

            );
            }
        }
                
export default Marketing