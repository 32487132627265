import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './header';
import Marketing from './marketing';
import LoggerItemView from './logger-item-view';


class App extends Component {
  render() {
    return (
      <div className="site-border">
      <div className="container">
        <Header/>
       <LoggerItemView/>
       <Marketing/>
      </div>
      </div>
    );
  }
}

export default App;
